
    .index-tabs {
        .tab-txt {
            vertical-align: middle;
            .iconfont {
                color: #909399;
                font-size: 18px;
                margin-right: 5px;
                position: relative;
                //top: -2px;
            }
        }
        .el-tabs__item.is-active {
            .iconfont {
                color: #409EFF;
            }
        }
        .el-tabs__item:hover {
            .iconfont {
                color: #409EFF;
            }
        }
    }
