
    .main-wrapper {
        height: 100%;
    }
    .subject-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        .select-left {
            display: flex;
            justify-content: start;
            align-items: center;
            .select {
                padding-right: 10px;
            }
        }
    }
    .subject-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: calc(100% - 61px);
    }
    .el-pagination {
        padding: 10px 5px 0;
    }
    .upload-file-content {
        .upload-btn {
            position: relative;

            .file-txt {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                width: 80px;
            }

            .file-input {
                opacity: 0;
                width: 120px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .file-list {
            margin: 10px 0;

            ul, li {
                margin: 0;
                padding: 0;
            }

            .files {
                .file {
                    list-style: none;
                    padding-left: 10px;
                }
            }
        }
    }
