
    .upload-resources-dialog {
        ::v-deep .el-dialog {
            .el-dialog__footer {
                text-align: center;
                .el-button+.el-button {
                    margin-left: 30px;
                }
            }
        }
    }
    .upload-resources {
        .upload-types {
            .types-left {
                float: left;
                .item {
                    display: inline-block;
                    margin-right: 20px;
                    .item-txt {
                        margin-right: 6px;
                    }
                }
            }
            .types-right {
                float: right;
                .upload-btn {
                    position: relative;
                    input {
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
            }
        }
        .table-wrapper {
            margin-top: 10px;
            .input-content {
                height: 30px;
                width: 100%;
            }
        }
    }
    .commit-required {
        width: 4%;
        color: #f56c6c;
        display: inline-block;
        & + .el-input {
            width: 96%;
        }
    }
    ::v-deep .el-input.is-error {
        .el-input__inner {
            border-color: #f56c6c;
        }
    }
    .item-txt-tip {
        color: #F56C6C;
        margin-left: 10px;
        font-size: 12px;
    }
